<template>
  <l-map
    :zoom="zoom"
    :center="centerMap"
  >
    <l-tile-layer
      :url="url"
      :attribution="attribution"
    />
    <l-marker
      v-for="(marker,index) of items"
      :key="index"
      :lat-lng="marker.geo"
      :icon="dataIcon"
      @click="openModal(marker)"
    />
    <b-modal
      id="modalMapView"
      v-model="isShowDetails"
      v-b-modal.modal-scrollable
      size="md"
      title="Informações"
      ok-only
      ok-title="Fechar"
    >
      <b-card>
        <b-row
          v-for="(info, index) of currentInformations.informations"
          :key="index"
        >
          <b-col>
            <b>Serial:</b> <span style="font-size: 12px">{{ info.serial }}<br></span>
            <b>Último Usuário</b>: <span style="font-size: 12px">{{ info.last_user }}<br></span>
            <b>Última Sincronização:</b> <span style="font-size: 12px">{{ formatDate(info.last_sync) }}</span>
            <i2-divider />
          </b-col>
        </b-row>
      </b-card>
    </b-modal>
  </l-map>
</template>

<script>
import {
  LMap, LTileLayer, LMarker,
} from 'vue2-leaflet'
import 'leaflet/dist/leaflet.css'
import markerIconPng from 'leaflet/dist/images/marker-icon.png'
import { Icon } from 'leaflet'
import moment from 'moment-timezone'

export default {
  name: 'MapViewer',
  components: {
    LMap, LTileLayer, LMarker,
  },
  props: {
    items: {
      type: Array,
      required: true,
    },
    // eslint-disable-next-line vue/require-default-prop
    center: {
      type: Object,
      required: false,
    },
  //   zoom: {
  //     type: Number,
  //     default: 8,
  //     required: false,
  //   },
  },
  data: () => ({
    isShowDetails: false,
    currentInformations: { informations: [] },
    url: 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png',
    attribution: '&copy; <b>DashboardPRO</b> ',
    centerMap: { lat: 0, lon: 0 },
    zoom: 8,
    markerLatLng: [],
    dataIcon: new Icon({
      iconUrl: markerIconPng,
      iconSize: [20, 35],
      iconAnchor: [12, 41],
    }),
  }),
  watch: {
    center(value) {
      if (value) {
        this.centerMap = value
      }
    },
  },
  methods: {
    openModal(value) {
      this.isShowDetails = true
      this.currentInformations = value
    },
    formatDate(date) {
      return moment(date).format('D/MM/YYYY')
    },
  },
}
</script>

<style scoped>

</style>
