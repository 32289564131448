<template>
  <div>
    <b-card-header>
      <b-card-title>
        <h1>
          Gráficos
        </h1>
      </b-card-title>
    </b-card-header>
    <b-card
      title="Localização dos Dispositivos"
    >
      <b-row class="align-items-center">
        <b-col
          sm="4"
          md="4"
        >
          <i2-form-input-date
            v-model="modelMaps.filterDate"
            label="Data"
            :value="modelMaps.filterDate"
          />
        </b-col>
        <b-col sm="4">
          <i2-form-input
            v-model="modelMaps.serial"
            label="Seriais"
            placeholder="Seriais"
          />
        </b-col>
        <b-col
          sm="3"
          md="3"
        >
          <i2-form-vue-select
            v-model="modelMaps.unitSelected"
            :value="modelMaps.unitSelected"
            label="Unidades Organizacionais"
            :options="modelMaps.unitsOptions"
            name="unitOptions"
          />
        </b-col>
        <b-col
          sm="1"
          md="1"
        >
          <b-button
            variant="primary"
            @click="getDevicesLocalization"
          >
            <feather-icon
              icon="SearchIcon"
            />
          </b-button>
        </b-col>

      </b-row>
      <b-row>
        <b-col class="map-viewer-container">
          <map-viewer
            :center="modelMaps.center" :items="modelMaps.items"
          />
        </b-col>
      </b-row>
    </b-card>
    <i2-divider />
  </div>

</template>

<script>
import MapViewer from '@/components/app/MapViewer'
import moment from 'moment-timezone'

export default {
  name: 'Maps',
  components: {
    MapViewer,
  },
  data() {
    return {
      modelMaps: {
        unitsOptions: [],
        serial: '',
        unitSelected: { value: null, text: 'Todos' },
        filterDate: '',
        items: [],
        center: { lat: 0, lon: 0 },
      },
    }
  },
  async mounted() {
    const today = new Date()
    this.getLocation()
    this.modelMaps.filterDate = moment(today).subtract(1, 'day').format('DD/MM/YYYY')
    this.getOrganizationUnits()
  },
  methods: {
    getOrganizationUnits() {
      this.modelMaps.unitsOptions = [{
        value: null,
        text: 'Todos',
      }]

      this.modelMaps.unitSelected = {
        value: null,
        text: 'Todos',
      }

      this.$http.get('organization_units').then(response => {
        response.forEach(unit => {
          this.modelMaps.unitsOptions.push(
            {
              value: unit,
              text: unit.path,
            },
          )
        })
        this.getDevicesLocalization()
      })
    },
    getDevicesLocalization() {
      if (!this.modelMaps.filterDate) {
        return
      }
      this.modelMaps.items = []
      const unit = this.modelMaps.unitSelected.value ? this.modelMaps.unitSelected.value.uuid : ''
      const filter = `date=${this.modelMaps.filterDate}&unit=${unit}&serial=${this.modelMaps.serial}`
      this.$http.get(`devices/localization?${filter}`).then(response => {
        if (!response || response.length === 0) {
          this.modelMaps.items = []
          return
        }
        this.modelMaps.center = {
          lat: response[0].geo[0],
          lon: response[0].geo[1],
        }
        response.forEach(device => {
          this.modelMaps.items.push(device)
        })
      })
    },
    getLocation() {
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(position => {
          this.modelMaps.center = { lat: position.coords.latitude, lon: position.coords.longitude }
        })
      }
    },
  },
}
</script>

<style scoped>
.map-viewer-container {
    width: 100%;
    height: 500px;
    z-index: 1 !important;
}
</style>
